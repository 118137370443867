import axios from "axios";

let headers = {
  "cache-control": "no-cache",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
  "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
};
// let accessToken = localStorage.getItem("token");

// if (accessToken && accessToken !== "") {
//     headers.Authorization = `Bearer ${accessToken}`;
// }

export default axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000/api/v1/"
      : "https://menu-api.tpdb.in/api/v1/",
  headers: headers,
});
