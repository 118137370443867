<template>
  <div class="home">
    <Header />
    <Hero />
    <div class="container-fluid mb-3">
      <div class="card">
        <div class="card-body">
          <h5 class="text-center">Welcome to eMenu</h5>
          <p class="text-muted">
            Restaurant Management Software Comes With Amazing Features Like
            Restaurant Billing Software, Online Food Ordering, QR Code Menu,
            Table Booking, etc.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header";
import Hero from "@/components/Hero";

export default {
  name: "app",
  components: {
    Header,
    Hero,
  },
};
</script>
