import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "./assets/custom.scss";
import Toaster from "@meforma/vue-toaster";
import packageInfo from "../package.json";

import "bootstrap-vue/dist/bootstrap-vue.css";
// import bootstrap from "./bootstrap";

// import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/sass/app.scss";

const app = createApp(App);

app.config.globalProperties.$filters = {
  currency(value) {
    return parseFloat(value).toLocaleString("en-in", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },
};
app.config.globalProperties.$appName = packageInfo.name.toUpperCase();
app.config.globalProperties.$appVersion = `v${packageInfo.version}`;
app
  .use(store)
  .use(router)
  .use(Toaster)
  // .use(bootstrap)
  // .use(BootstrapVue)
  // .use(IconsPlugin)
  .mount("#app");
