import { createRouter, createWebHistory } from "vue-router";
// import { createRouter, createWebHashHistory } from "vue-router";
// import HomeView from "../views/HomeView.vue";
import Home from "@/views/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "eMenu Home",
    },
  },
  {
    path: "/:outlet",
    name: "Home",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      title: "eMenu",
    },
  },
  {
    path: "/:outlet/:catcode",
    name: "Menus",
    component: () =>
      import(/* webpackChunkName: "menu" */ "@/views/MenuView.vue"),
    meta: {
      title: "Menus",
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  {
    path: "/:outlet/order",
    name: "Cart",
    component: () => import("@/views/Cart"),
  },
  {
    path: "/:outlet/thanks",
    name: "Thanks",
    component: () => import("@/views/Thanks"),
    meta: {
      title: "Thank you for your order",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "eMenu";
  next();
});

export default router;
