<template>
  <div>
    <nav class="navbar header has-shadow is-primary">
      <div class="navbar-brand">
        <router-link to="/">
          <img class="logo" src="@/assets/images/logo.svg" alt="eMenu" />
        </router-link>
      </div>
      <div class="header-right">
        <ul class="navbar-nav flex-row">
          <li class="navbar-item a1">
            <a
              role="button"
              aria-label="menu"
              @click="go(`/${this.$route.params.outlet}/order`, false)"
            >
              <img src="@/assets/images/bag.svg" />
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    go(url, auth) {
      if (auth && !this.user) {
        this.googleSignIn();
      } else {
        this.$router.push(url);
      }
    },
    ...mapActions({
      googleSignIn: "auth/googleSignIn",
    }),
  },
  computed: {
    user() {
      return (this.$store.state.auth || {}).use || null;
    },
  },
};
</script>
<style lang="css" soped>
.navbar {
  min-width: 200px;
}
.header-right {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
}
.hader-right a {
  padding: 5px;
  color: white;
}
.navbar-item img {
  width: 24px;
  height: 24x;
  border-radius: 50px;
}
.navbar-brand {
  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  min-height: 3.25rem;
}
.navbar.has-shadow {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
}
.navbar.is-primary {
  background: linear-gradient(87deg, #fb6340 0, #fbb140 100%) !important;
}
.navbar {
  background-color: #fff;
  min-height: 3.25rem;
  z-index: 30;
}
.iconalign {
  display: flex;
  align-items: center;
  width: 92%;
  height: 100%;
  padding-left: 7px;
  padding-right: 14px;
}
.iconalign1 {
  display: flex;
  align-items: center;
  width: 78%;
  height: 100%;
  padding-left: 0px;
}
.iconalign2 {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: 0px;
  padding-top: 1px;
}
.navbar.is-primary .navbar-brand > a.navbar-item:hover {
  background: linear-gradient(87deg, #fb6340 0, #fbb140 100%) !important;
}
.navbar-item img {
  width: 24px;
  height: 24px;
  max-width: 100%;
  border-radius: 50px;
  max-height: 1.75rem;
}
a {
  color: #3273dc;
  cursor: pointer;
  text-decoration: none;
}
.navbar-menu {
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(10, 10, 10, 0.1);
  padding: 0.5rem 0;
  display: none;
}
.navbar-link,
a.navbar-item {
  cursor: pointer;
}
.navbar-item,
.navbar-link {
  color: #4a4a4a;
  padding: 0.5rem;
}
.a1 {
  display: inline-flex;
  align-items: center;
}
.icon {
  width: 7rem;
  height: 7rem;
}
.shadow,
.profile-page .card-profile .card-profile-image img {
  -webkit-box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1),
    0 5px 15px rgba(0, 0, 0, 0.07) !important;
  box-shadow: 0 15px 35px rgba(50, 50, 93, 0.1), 0 5px 15px rgba(0, 0, 0, 0.07) !important;
}
.button.is-danger {
  border-color: transparent;
  color: #fff;
}
.iconcenter {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 158px;
}
.boxbtn {
  width: 82%;
  height: 100%;
  display: flex;
  align-items: center;
}
.boxbtn1 {
  width: 67%;
  height: 100%;
  display: flex;
  align-items: center;
}
.headeralign {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 41px;
  margin-top: 3px;
}
/* .img {
  width: 34px;
  height: 37px;
  border-radius: 50px;
} */
</style>
