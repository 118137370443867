<template>
  <div>
    <component :is="this.$route.meta.layout || 'div'">
      <router-view />
    </component>
  </div>
</template>
<script>
// import { mapGetters } from "vuex";

export default {
  computed: {
    // ...mapGetters(["user"]),
  },
  mounted() {
    console.log("Layout", this.$route.meta.layout);
  },
};
</script>
<style lang="scss"></style>
